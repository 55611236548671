import React from "react"  
// import React, { useState, useEffect } from "react"
// import { navigate } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"

const Contact = () => {
    return (
    <Layout showCookieConsent={false}>
        <SEO title="Contact us" />
        <div className={"container"}>

            <div className={"content"}>
                <div className={"title"}>
                    <h1>Contact us</h1>
                </div>

                <div id="contact-form">
                    <form action="https://getform.io/f/ed7cd98d-6950-46c2-a1b0-92f8a96e2eba" method="POST"
                    //onSubmit={event => {
                    //    event.preventDefault()
                    //    console.log("event: ", event, "this: ", this)
                    //    console.log(event.target)
                    //    fetch("")
                        // TODO: submit to getform.io AND/OR v1.vokabox.com?
                    //    navigate("/contact-thanks")
                    //    }}>
                    >
                        <label htmlFor="email">Email*</label>
                        <input type="email" id="email" name="email" required placeholder="Your email" />
                        <label htmlFor="name">Name*</label>
                        <input type="text" id="name" name="name" required minLength="3" maxLength="200" placeholder="Your name"/>
                        <label htmlFor="message">Message*</label>
                        <textarea id="message" name="message" rows="8" required placeholder="Your message..." />
                        <button className="button primary" type="submit" name="submit"><i className="fas fa-paper-plane"></i> Submit</button>
                    </form>
                </div>
            </div>
        </div>
    </Layout>
)}

export default Contact